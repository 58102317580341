<template>
  <div>
    <div
      ref="map"
      class="map"
    />
    <div class="stats">
      <h6 class="mt-1">
        GEO-LOCATIONS
      </h6>
      <p class="h3 m-0">
        <span class="mr-xs fw-normal" />
        <i class="fa fa-map-marker" />
      </p>
    </div>
  </div>

</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4maps from '@amcharts/amcharts4/maps'
/* eslint-disable camelcase */
// import am4geodata_usaHigh from '@amcharts/amcharts4-geodata/usaHigh'
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow'

import cities from './mock'

export default {
  name: 'Map',
  components: {},
  data() {
    return {
      animateNumberOptions: {
        duration: 2000,
        easing: 'easeInQuad',
        formatValue(value) {
          let number = value.toFixed(0)
          const numberAsArrayWithSapces = []
          while (number >= 1) {
            numberAsArrayWithSapces.unshift(number % 1000)
            number = (number / 1000).toFixed()
          }
          return numberAsArrayWithSapces.join(' ')
        },
      },
    }
  },
  mounted() {
    const map = am4core.create(this.$refs.map, am4maps.MapChart)
    map.geodata = am4geodata_worldLow
    map.projection = new am4maps.projections.Projection()
    map.chartContainer.wheelable = false
    map.seriesContainer.draggable = false
    map.seriesContainer.resizable = false
    const polygonSeries = map.series.push(new am4maps.MapPolygonSeries())
    polygonSeries.useGeodata = true
    map.homeZoomLevel = 1.2

    map.zoomControl = new am4maps.ZoomControl()
    map.zoomControl.align = 'left'
    map.zoomControl.valign = 'bottom'
    map.zoomControl.dy = -20

    map.zoomControl.minusButton.background.fill = am4core.color('#C7D0FF')
    map.zoomControl.minusButton.background.fillOpacity = 0.24
    map.zoomControl.minusButton.background.stroke = null
    map.zoomControl.plusButton.background.fill = am4core.color('#C7D0FF')
    map.zoomControl.plusButton.background.fillOpacity = 0.24
    map.zoomControl.plusButton.background.stroke = null
    map.zoomControl.plusButton.label.fill = am4core.color('#fff')
    map.zoomControl.plusButton.label.fontWeight = 600
    map.zoomControl.plusButton.label.fontSize = 16
    map.zoomControl.minusButton.label.fill = am4core.color('#fff')
    map.zoomControl.minusButton.label.fontWeight = 600
    map.zoomControl.minusButton.label.fontSize = 16
    const plusButtonHoverState = map.zoomControl.plusButton.background.states.create('hover')
    plusButtonHoverState.properties.fillOpacity = 0.24
    const minusButtonHoverState = map.zoomControl.minusButton.background.states.create('hover')
    minusButtonHoverState.properties.fillOpacity = 0.24

    const polygonTemplate = polygonSeries.mapPolygons.template
    polygonTemplate.tooltipText = '{name}'
    polygonTemplate.fill = am4core.color('#474D84')
    polygonTemplate.fillOpacity = 1
    const hs = polygonTemplate.states.create('hover')
    hs.properties.fillOpacity = 0.5

    polygonTemplate.stroke = am4core.color('#6979C9')
    polygonTemplate.strokeOpacity = 1

    const citySeries = map.series.push(new am4maps.MapImageSeries())
    citySeries.data = cities
    citySeries.dataFields.value = 'size'

    const city = citySeries.mapImages.template
    city.nonScaling = true
    city.propertyFields.latitude = 'latitude'
    city.propertyFields.longitude = 'longitude'
    const circle = city.createChild(am4core.Circle)
    circle.fill = am4core.color('#C7D0FF')
    circle.stroke = am4core.color('#ffffff')
    circle.strokeWidth = 0
    const circleHoverState = circle.states.create('hover')
    circleHoverState.properties.strokeWidth = 1
    circle.tooltipText = '{tooltip}'
    circle.propertyFields.radius = 'size'

    this.map = map
  },
}
</script>

<style src='./Map.scss' lang='scss' />
