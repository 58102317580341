export default [
  {
    latitude: 40.717079,
    longitude: -74.00116,
    size: 6,
    tooltip: 'New York',
  },
  {
    latitude: 33.145235,
    longitude: -83.811834,
    size: 7,
    tooltip: 'Oconee National Forest',
  },
  {
    latitude: 37.792032,
    longitude: -122.394613,
    size: 4,
    tooltip: 'San Francisco',
  },
  {
    latitude: 26.935080,
    longitude: -80.851766,
    size: 9,
    tooltip: 'Lake Okeechobee',
  },
  {
    latitude: 36.331308,
    longitude: -83.336050,
    size: 4,
    tooltip: 'Grainger County',
  },
  {
    latitude: 36.269356,
    longitude: -76.587477,
    size: 8,
    tooltip: 'Chowan County',
  },
  {
    latitude: 30.700644,
    longitude: -95.145249,
    size: 6,
    tooltip: 'Lake Livingston',
  },
  {
    latitude: 34.546708,
    longitude: -90.211471,
    size: 5,
    tooltip: 'Tunica County',
  },
  {
    latitude: 32.628599,
    longitude: -103.675115,
    size: 5,
    tooltip: 'Lea County',
  },
  {
    latitude: 40.456692,
    longitude: -83.522688,
    size: 5,
    tooltip: 'Union County',
  },
  {
    latitude: 33.844630,
    longitude: -118.157483,
    size: 6,
    tooltip: 'Lakewood Mutual',
  },
]
